<template>
  <table-view
    ref="tableView"
    url="/dispatch/listTaskPage"
    delete-url="/dispatch/deltask"
    :filterable="false"
    :filter-form="dataForm"
    :batch-removable="isAuthed('dispatch_record_delete')"
    :uploadable="isAuthed('dispatch_record_add')"
    upload-title="批量上传调运任务"
    upload-url="/dispatch/task"
    :headers="headers"
    selectable
    show-index
    :pageSize=100
    single
    :split-index="2"
    @showResult='showResult'
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-radio-group v-model="dataForm.day" @change="changeDateType">
        <el-radio label="1">今日</el-radio>
        <el-radio label="2">明日</el-radio>
        <el-radio label="3">
          <el-date-picker
            v-model="dataForm.dispatchTime"
            type="date"
            value-format="timestamp"
            placeholder="任务时间"
            :disabled="dataForm.day !== '3'"
          ></el-date-picker>
        </el-radio>
      </el-radio-group>
      <el-input v-model="dataForm.siteName" clearable placeholder="工地名称" style="margin-left: 10px;"></el-input>
      <el-input v-model="dataForm.baseName" clearable placeholder="所属基地"></el-input>
      <!-- <el-input v-model="dataForm.number" clearable placeholder="自编号"></el-input> -->
      <el-select v-model="dataForm.number" clearable filterable placeholder="自编号">
        <el-option v-for="item in carList" :key="item.number" :label="item.number" :value="item.number"></el-option>
      </el-select>
      <el-select v-model="dataForm.status" clearable filterable placeholder="调运任务状态">
        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
      <el-button v-if="isAuthed('dispatch_decision_send')" @click="send" type="primary" :loading="loading">下发</el-button>
    </el-form>
    <template slot="insertion">
      <el-table-column label="锁定状态" :width="80" header-align="center" align="center">
        <template slot-scope="{row}">
          <el-tag v-if="row.locked">
            <i class="el-icon-lock" @click="lockTask(row.id)"></i></el-tag>
          <el-tag v-else>
            <i  class="el-icon-unlock" @click="lockTask(row.id)"></i></el-tag>
        </template>
      </el-table-column>
      <el-table-column label="需要车辆类型" :width="100" header-align="center" align="center">
        <template slot-scope="{row}">
          <car-list-dropdown-new
            :row="row"
            @close="query"
          ></car-list-dropdown-new>
        </template>
      </el-table-column>
      <el-table-column label="实际车辆" :width="150" header-align="center" align="center">
        <template slot-scope="{row}">
          <el-tag
            type="info"
          >{{row.number}}-{{row.carSize}}米</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        :width="150"
        sortable="custom"
        prop="companyName"
        label="所属公司">
        <template slot-scope="{row}">
          <company-list-dropdown
            :companyOptions="companyOptions"
            :row="row"
            @close="query"
          ></company-list-dropdown>
        </template>
      </el-table-column>
    </template>
    <el-dialog
      title="批量导入结果"
      :visible.sync="dialogVisible"
      width="80%"
      height=600px
      :before-close="handleClose">
      <p style="fonr-size=12px;">共有 {{errorList.length}} 条错误记录，详情如下:</p>
      <div style="height: 150px;overflow-y:auto;">
        <p style="fonr-size=12px;border-bottom:1px solid red" v-for="(item, index) in errorList" :key="index">{{index+1}}: &nbsp;&nbsp; {{item}} </p>
      </div>
      <div v-if="resultList.length > 0">总共可以生成 {{resultList.length}} 条调运记录</div>
      <el-table
        size="mini"
        v-if="resultList.length > 0"
        :data="resultList"
        max-height="550"
        style="width: 100%">
        <el-table-column
          prop="dispatchTime"
          label="时间"
          width="180">
        </el-table-column>
        <el-table-column
          prop="demandCarSize"
          label="需求车辆类型">
        </el-table-column>
        <el-table-column
          prop="number"
          label="实际车辆">
        </el-table-column>
        <el-table-column
          prop="companyName"
          width="180"
          label="所属公司">
        </el-table-column>
        <el-table-column
          prop="constructionUnit"
          label="施工单位">
        </el-table-column>
        <el-table-column
          prop="siteName"
          label="工地名称"
          width="240">
        </el-table-column>
        <el-table-column
          prop="constructionPart"
          width="180"
          label="施工部位">
        </el-table-column>
        <el-table-column
          prop="quantityNumber"
          width="120"
          label="方量">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
import CarListDropdownNew from '@/components/templates/car-list-dropdown-new'
import CompanyListDropdown from '@/components/templates/company-list-dropdown'

export default {
  name: 'dispatch-task',

  components: { TableView, CarListDropdownNew, CompanyListDropdown },

  data () {
    return {
      dialogVisible: false,
      errorList: [],
      resultList: [],
      companyOptions: [],
      headers: [
        { id: 1,
          prop: 'dispatchTime',
          label: '时间',
          width: 150,
          sortable: 'custom'
        },
        {
          id: 5,
          prop: 'dispatchTaskStatus',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 1:
                return '待完成'
              case 2:
                return '进行中'
              case 3:
                return '已完成'
              case 4:
                return '失败'
              case 5:
                return '待下发'
              case 6:
                return '待确认'
              default:
                return '未知'
            }
          },
          sortable: 'custom'
        },
        { id: 16, prop: 'statusInfo', label: '上传方量状态', width: 120 },
        { id: 10, prop: 'mixingTable', label: '拌台', width: 100, sortable: 'custom' },
        { id: 2, prop: 'constructionUnit', label: '施工单位', width: 100, sortable: 'custom' },
        { id: 3, prop: 'siteName', label: '工地名称', minWidth: 200, sortable: 'custom' },
        { id: 4, prop: 'constructionPart', label: '施工部位', width: 120, sortable: 'custom' },
        { id: 8, prop: 'gradation', label: '级配', width: 100, sortable: 'custom' },
        { id: 11, prop: 'quantityNumber', label: '方量', width: 80, sortable: 'custom' },
        { id: 9, prop: 'dangerNames', label: '危险源', width: 100, sortable: 'custom' },
        { id: 12, prop: 'siteAddress', label: '工地地址', width: 150, sortable: 'custom' },
        { id: 14, prop: 'siteHeadName', label: '联系人', width: 80, sortable: 'custom' },
        { id: 15, prop: 'siteHeadPhone', label: '联系人电话', width: 100, sortable: 'custom' },
        { id: 6, prop: 'baseName', label: '所属基地', width: 100, sortable: 'custom' },
        { id: 7, prop: 'executeBaseName', label: '执行基地', width: 100, sortable: 'custom' }
      ],
      dataForm: {
        day: '1',
        baseName: '',
        siteName: '',
        dispatchTime: '',
        number: '',
        status: ''
      },
      statusList: [{
        label: '待完成',
        value: 1
      }, {
        label: '进行中',
        value: 2
      }, {
        label: '已完成',
        value: 3
      }, {
        label: '待下发',
        value: 5
      }, {
        label: '待确认',
        value: 6
      }],
      carList: []
    }
  },
  mounted () {
    this.$http({
      url: this.$http.adornUrl('/company/list'),
      method: 'post',
      data: {
        companyName: ''
      }
    }).then((data) => {
      this.companyOptions = data.datas
    })

    this.$http({
      url: this.$http.adornUrl('/car/list'),
      method: 'post',
      data: { }
    }).then((data) => {
      this.carList = data.datas
    })
  },
  created () {
    this.dataForm.dispatchTime = this.getToday()
  },
  methods: {
    query () {
      if (this.dataForm.day === '1') {
        this.dataForm.dispatchTime = this.getToday()
      }
      if (this.dataForm.day === '2') {
        this.dataForm.dispatchTime = this.getTomorrow()
      }
      this.$refs.tableView.queryData()
    },
    async send () {
      const selection = this.$refs.tableView.$refs.table.selection
      if (!selection.length) return this.$opts.alert('请先选择记录')
      this.loading = true
      try {
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/lowerConfirm/accept'),
          method: 'post',
          data: selection.map(s => s.id)
        })
        this.loading = false
        if (data.code === 200) {
          this.$opts.success()
          this.query()
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    changeDateType () {
      if (this.dataForm.day === '3') {
        this.dataForm.dispatchTime = ''
      }
    },
    getToday () {
      var day = new Date()
      day.setTime(day.getTime())
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    },
    getTomorrow () {
      var day = new Date()
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000)
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    },
    showResult (options) {
      this.dialogVisible = true
      console.log(options)
      this.errorList = options.error
      this.resultList = options.data
    },
    handleClose (done) {
      this.$confirm('确认不保存直接关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    submitData () {
      let msg = '确认提交全部调运任务数据？'
      if (this.errorList.length > 0) {
        msg = '有错误内容将不会被导入，确认部分提交调运任务数据'
      }
      this.$confirm(msg)
        .then(_ => {
          this.saveData()
        })
        .catch(_ => {})
    },
    async saveData () {
      const data = await this.$http({
        url: this.$http.adornUrl('/dispatch/task/import'),
        method: 'post',
        data: this.resultList
      })
      this.loading = false
      if (data.code === 200) {
        this.$opts.success()
        this.query()
        this.dialogVisible = false
      }
    },
    lockTask (id) {
      console.log(this.currentPage)
      console.log(this.tableData)
      this.$http({
        url: this.$http.adornUrl('/dispatch/locktask'),
        method: 'post',
        data: id
      }).then((data) => {
        if (data.datas == null) {
          this.$message({
            message: '锁定记录失败',
            type: 'warning'
          })
        } else {
          this.query()
          let msg = '锁定记录成功'
          if (!data.datas) {
            msg = '记录解锁成功'
          }
          this.$message({
            message: msg,
            type: 'success'
          })
        }
      })
    }
  }
}
</script>
